"use client";
import * as React from "react";
import {
  useTheme,
  Box,
  Divider,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { shallow } from "zustand/shallow";
import { useSnackbar } from "notistack";
import useWheelList from "@/hooks/fetchers/useWheelList";
import useWheelHistory from "@/hooks/fetchers/useWheelHistory";
import Spinner from "./Spinner";
import { useConfigStore } from "@/stores/configStore";

const TYPES: {
  [key: string]: string;
} = {
  wheel_ticket: "ตั๋ว",
  wheel_credit: "ซื้อ",
};

const Wheel = () => {
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const { data: wheels } = useWheelList();
  const { data: histories, refresh } = useWheelHistory();
  const { enqueueSnackbar } = useSnackbar();
  const segments: any = wheels?.data;
  const winningSegment = segments?.find((o: any) => o?.value === 5000);
  const [congratulation, setCongratulation] = React.useState("");

  function createData(name: string, calories: string) {
    return { name, calories };
  }

  const rows = [
    createData(config?.wheel_max_deposit || "0", "1"),
    createData("ราคาซื้อ", "จำนวน"),
    createData(config?.wheel_buy_credit || "0", "1 ครั้ง"),
  ];

  const segColors: string[] = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    "#0F1C40",
    "#0E76EA",
    "#0F1C40",
    "#0E76EA",
    "#0F1C40",
    "#0E76EA",
    "#0F1C40",
    "#0E76EA",
  ];

  const closeCongratulation = () => {
    setCongratulation("");
  };

  const onFinished = (winner: any) => {
    refresh();
    setCongratulation(winner.message);
    enqueueSnackbar(winner.message, {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  return (
    <Box p={2}>
      <Box>
        <Divider>
          <Typography variant="h5" align="center" color={(theme) => theme.gradient[900]}>
            กงล้อ
          </Typography>
        </Divider>
      </Box>
      <Box sx={{ position: "relative", overflow: "hidden" }} width="100%">
        {congratulation && (
          <Box
            onClick={closeCongratulation}
            sx={{
              borderRadius: 2,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(5px)",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1,
              pt: 4,
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 2,
                }}
              >
                <Typography
                  variant="h5"
                  fontSize={18}
                  fontWeight={"bold"}
                  color="black"
                  align="center"
                  mt={4}
                  ml={5}
                >
                  {congratulation}
                </Typography>
              </Box>
              <img
                src="/assets/card-cong.avif"
                alt={congratulation}
                width="100%"
              />
            </Box>
          </Box>
        )}
        <Box mt={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color={(theme) => theme.gradient[900]}>คลิกเพื่อดูเงื่อนไข</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ color: (theme) => theme.gradient[900] }}>
                      <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>สะสมยอดฝากครบ</TableCell>
                      <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>ได้รับ TICKET</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row: any) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row" sx={{ color: (theme) => theme.gradient[900] }}>
                          {row.name}
                        </TableCell>
                        <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>{row.calories}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box mt={2}>
          {segments ? (
            <Spinner
              segments={segments || []}
              segColors={segColors || []}
              winningSegment={winningSegment}
              onFinished={(winner: any) => onFinished(winner)}
              primaryColor={"#1A2027"}
              contrastColor="white"
              buttonText={""}
              isOnlyOnce={false}
              size={149}
              upDuration={100}
              downDuration={600}
              fontFamily={'"Noto Sans Thai", sans-serif'}
              config={config}
            />
          ) : (
            <Box
              p={4}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Box mt={3}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>วัน/เวลา</TableCell>
                  <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>ประเภท</TableCell>
                  <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>จำนวน</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {histories
                  ? histories.map((row: any) => (
                    <TableRow
                      key={row.created_at}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center" component="th" scope="row" sx={{ color: (theme) => theme.gradient[900] }}>
                        {row.created_at}
                      </TableCell>
                      <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>{TYPES[row.type]}</TableCell>
                      <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>{row.coin}</TableCell>
                    </TableRow>
                  ))
                  : histories}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box >
  );
};

export default Wheel;
