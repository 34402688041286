"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import { Divider, Grid, Typography, useTheme } from "@mui/material";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useWithdrawStore } from "@/stores/withdrawStore";
import { useSnackbar } from "notistack";
import { useAppModalStore } from "@/stores/appModalStore";
import numeral from "numeral";
import { LoadingButton } from "@mui/lab";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import useMe from "@/hooks/fetchers/useMe";
import { Stack } from "@mui/system";

export default function WithdrawFormAll() {
  const theme = useTheme();
  const [user] = useAuthStore((state) => [state.user], shallow);
  const [withdraw] = useWithdrawStore((state) => [state.withdraw], shallow);
  const [loading, setLoading] = React.useState(false);
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const { refresh: refreshPro } = usePromotionHistory();
  const { refresh: refreshMe } = useMe();

  const onSubmit = async () => {
    setLoading(true);
    const amount = numeral(user?.balance).value() || 0;
    await withdraw(amount)
      .then((res: any) => {
        if (res?.errMessage) {
          enqueueSnackbar(res.errMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          setLoading(false);
        } else {
          enqueueSnackbar("สำเร็จ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          refreshPro();
          refreshMe();
          setOpen(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography align="center" color={(theme) => theme.gradient[900]}>จำนวนเงินทั้งหมด</Typography>
            <Divider
              sx={{
                // mt: -1,
                borderColor: theme.palette.primary.main,
                borderRadius: "10px",
                borderWidth: "1px",
                width: "20px",
              }}
            />
            <Typography
              align="center"
              fontSize="32px"
              fontWeight={"bold"}
              color={(theme) => theme.gradient[900] || theme.palette.primary.main}
            >
              {user?.balance}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              color: (theme) => theme.name === 'preset14' ? '#fff' : theme.gradient[900],
              backgroundColor: (theme) => theme.name === 'preset14' ? '#ffc100' : 'default',
              height: "50px",
              "&:hover": {
                color: (theme) => theme.name === 'preset14' ? '#fff' : 'default',
                backgroundColor: (theme) => theme.name === 'preset14' ? '#ffc100' : 'default',
              },
            }}
            onClick={() => onSubmit()}
          >
            แจ้งถอน
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
