import * as React from "react";
import MainMenu from "./MainMenu";
import BannerCarousel from "../components/carousel/BannerCarousel";
import Marquee from "../components/carousel/Marquee";
import { LayoutDashboard } from "@/components/layout";
import { UserWallet, GameMenu, DepositAndWithdrawButtonGroup } from "./";

export default function Dashboard() {
  return (
    <LayoutDashboard
      wallet={<UserWallet />}
      marquee={<Marquee />}
      bannerCarousel={<BannerCarousel />}
      depositAndWithdrawButtonGroup={<DepositAndWithdrawButtonGroup />}
      mainMenu={<MainMenu />}
      gameCategoryMenu={<GameMenu />}
    />
  );
}
BannerCarousel;
