"use client";

import React, { CSSProperties } from "react";
import { Carousel } from "react-responsive-carousel";
import { Box, Button, Typography, styled, useTheme } from "@mui/material";
import { Announcement } from "@/@types/announcement";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./style.css";

type Props = {
  items: Announcement[];
  onClickGettingPromotion?: (id: string | number) => void;
};

export default function AnnouncementCarousel({
  items,
  onClickGettingPromotion = () => { },
}: Props) {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const theme = useTheme();
  const handleChange = (index: number) => {
    setCurrentSlide(index);
  };
  return (
    <Carousel
      infiniteLoop
      autoPlay
      showArrows={false}
      centerMode
      showStatus={false}
      showThumbs={false}
      centerSlidePercentage={100}
      onChange={handleChange}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{
                ...indicatorStyles,
                background: theme.palette.primary.main,
                borderRadius: theme.shape.borderRadius,
              }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <li
            style={{
              ...indicatorStyles,
              background: theme.palette.primary.main,
              borderRadius: theme.shape.borderRadius,
            }}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}
    >
      {items.map((item, index) => (
        <Box
          key={item?.desc}
          mx={{
            xs: 0,
            md: 1,
          }}
        >
          <ImageLink url={item?.url}>
            <Box>
              <Image src={item?.image} />
            </Box>
          </ImageLink>
          {currentSlide == index && (
            <Box mb={4}>
              {item?.desc && (
                <Typography variant="body1" color="textPrimary">
                  <p dangerouslySetInnerHTML={{ __html: item?.desc }}></p>
                </Typography>
              )}
              {item?.promotion_id && (
                <Button
                  variant="contained"
                  sx={{
                    my: 2,
                  }}
                  onClick={() => {
                    onClickGettingPromotion(item?.promotion_id || "");
                  }}
                >
                  รับโปรโมชั่น
                </Button>
              )}
            </Box>
          )}
        </Box>
      ))}
    </Carousel>
  );
}

const ImageLink = ({
  url,
  children,
}: {
  url?: string;
  children?: React.ReactNode;
}) => {
  if (url && url.length > 0) return <a href={url}>{children}</a>;
  return children;
};

const Image = styled("img")({
  cursor: "pointer",
  width: "100%",
  borderRadius: "16px",
});

const indicatorStyles: CSSProperties = {
  background: "#fff",
  width: 32,
  height: 4,
  display: "inline-block",
  margin: "0 8px",
};
