"use client";

import React, { useState, useEffect, useRef } from "react";

import { useUserProfile } from "@/hooks";
import { styled } from "@mui/material/styles";
import { useAuthStore } from "@/stores/authStore";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Alert, AlertTitle, Divider, Stack, Typography, IconButton, Button, alpha } from "@mui/material";
import { useAppModalStore } from "@/stores/appModalStore";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { shallow } from "zustand/shallow";
import numeral from "numeral";
import { useConfigStore } from "@/stores/configStore";

type Menu = {
  key: string;
  label: string;
  icon?: string;
};

const proMenus: Menu[] = [
  { key: "removePro", label: "ออกโปร", icon: "ph:hand-deposit" },
  { key: "withdraw", label: "ถอนเงิน", icon: "bx:money-withdraw" },
];
const primaryMenus: Menu[] = [
  { key: "deposit", label: "ฝากเงิน", icon: "ph:hand-deposit" },
  { key: "withdraw", label: "ถอนเงิน", icon: "bx:money-withdraw" },
];

const menus: Menu[] = [
  { key: "historyAll", label: "ประวัติธุรกรรม", icon: "mdi:history" },
  { key: "affiliate", label: "แนะนำเพื่อน", icon: "mdi:invite" },
  { key: "refund", label: "คืนยอดเสีย", icon: "gridicons:refund" },
  { key: "cashbackTurnover", label: "คืนยอดเล่น", icon: "gridicons:money" },
  { key: "cashbackWinlose", label: "คืนยอดเสีย", icon: "gridicons:refund" },
  { key: "promotion", label: "โปรโมชั่น", icon: "ph:gift-fill" },
  { key: "coupon", label: "คูปอง", icon: "mdi:coupon" },
  { key: "wheel", label: "กงล้อ", icon: "lucide:ferris-wheel" },
  { key: "mysteryBox", label: "กล่องสุ่ม", icon: "ph:archive-bold" },
];

const Item = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
  textAlign: "center",
  transition: "all 0.12s ease-in-out",
  "& #icon": {
    color: theme.palette.primary.main,
    ...(theme.name === "preset3" || theme.name === "preset6" || theme.name === "preset5") && {
      color: "white",
    },
  },
  color: theme.palette.text.secondary,
  "&:hover": {
    transform: "scale(1.08)",
    cursor: "pointer",
    "& #icon-container": {
      background: theme.palette.primary.main,
    },
    "& #icon": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    "& #label": {
      fontWeight: 600,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      ...(theme.name === "preset5" && {
        color: "#86c01a",
      }),
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[8],
  cursor: "pointer",
  border: `2px solid ${alpha(theme.palette.primary.main, 0.3)}`,
  background: alpha(theme.palette.secondary.main, 0.6),
  ...((theme.name === "preset3" || theme.name === "preset6") && {
    background: theme.gradient[100],
    border: "none",
  }),
  ...(theme.name === "preset5" && {
    background: theme.gradient[100],
    border: "none",
  }),
  color: theme.palette.getContrastText(theme.palette.primary.main),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 85,
  width: 85,
  borderRadius: 10,
  transition: "all 0.12s ease-in-out",
}));

const MenuWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  overflowX: "auto",
  scrollSnapType: "x mandatory",
  position: "relative",
  "& > *": {
    scrollSnapAlign: "center",
  },
  [theme.breakpoints.down("md")]: {
    paddingBottom: theme.spacing(1),
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "center",
    alignItems: "start",
    overflowX: "hidden",
  },
}));
const PreMenu = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  marginBottom: theme.spacing(1),
}));
const PreMenuPro = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  marginBottom: theme.spacing(1),
}));
const BoxProfile = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  marginBottom: theme.spacing(1),
}));
const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,
  backgroundColor: alpha(theme.palette.background.default, 0.7),
  "&:hover": {
    backgroundColor: theme.palette.background.default,
  },
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export default function MainMenu() {
  const {
    isLoading,
    currentBalance,
    promotionHistory,
    handleRemovePro,
    isPromotionHistoryLoading,
    // user,
    isBonus,
    // handleOpenModal,
  } = useUserProfile();
  const router = useRouter();
  const [user] = useAuthStore((state) => [state.user], shallow);
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const [cashback, setCashback] = useState<number>(0);

  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isMobile && menuRef.current) {
      const depositIndex = menus.findIndex((menu) => menu.key === "deposit");
      if (depositIndex !== -1) {
        const itemWidth = 85; // Width of each menu item
        const offset =
          depositIndex * itemWidth -
          menuRef.current.clientWidth / 2 +
          itemWidth / 2;
        menuRef.current.scrollTo({ left: offset, behavior: "smooth" });
      }
    }
  }, [isMobile]);

  const handleItemClick = (key: string) => {
    if (key === "removePro") {
      handleRemovePro(); // Call the handleRemovePro function
    } else if (key === "mystery-box") {
      router.push("/mystery-box");
    } else {
      setOpen(true);
      setData(key);
    }
  };

  const scrollMenu = (direction: "left" | "right") => {
    if (menuRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = menuRef.current;
      const scrollAmount = clientWidth / 3; // Scroll by one-third of the container width
      let newScrollPosition = 
        direction === "left" ? scrollLeft - scrollAmount : scrollLeft + scrollAmount;

        if (newScrollPosition < 0) {
          // Scroll to the end if scrolling left beyond the start
          newScrollPosition = scrollWidth - clientWidth;
        } else if (newScrollPosition + clientWidth >= scrollWidth) {
          // Scroll to the start if scrolling right beyond the end
          newScrollPosition = 0;
        }
        menuRef.current.scrollTo({ left: newScrollPosition, behavior: "smooth" });
    }
  };
  
  const renderUserCard = (
    <>
      {/* ปกติ */}
      {!isBonus && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid container spacing={1} xs={12} md={5} sx={{
          padding: 1,
          borderRadius: 1,
          boxShadow: 1,
          overflow: "hidden",
          width: "100%",
          backgroundColor: "#061114", // Dark background
          border: "2px solid rgba(252,220,130,1)", // Border color from the image
        }}>
          <Grid item xs={8}>
            <BoxProfile
              sx={{
                padding: 0, // Removed padding for a tighter fit
                paddingTop: 0.75,
                borderRadius: 1,
                boxShadow: 1,
                overflow: "hidden",
                width: "100%",
                backgroundColor: "#061114", // Darker background for profile box
                border: "2px solid rgba(252,220,130,1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Aligns icon to the left and text to the right
              }}
            >
              <PermIdentityOutlinedIcon
                sx={{
                  width: 60,
                  height: 60,
                  color: "rgba(252,220,130,1)", // Icon color matching the border
                  marginLeft: 2, // Add margin to the left to create space
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end", // Align items to the right
                  flexGrow: 1, // Ensures the box takes up remaining space
                  paddingRight: 2, // Adds padding to the right
                }}
              >
                <Typography
                  color={theme.palette.primary.main}
                  fontWeight="bold"
                  sx={{ lineHeight: 1.2, marginBottom: 0.5 }}
                >
                  {user?.gameusername}
                </Typography>
                <Typography
                  color={theme.palette.primary.main}
                  textAlign="right"
                  sx={{ lineHeight: 1.2, marginBottom: 0.5 }}
                >
                  ยอดเงินคงเหลือ
                </Typography>
                <Typography
                  align="right"
                  fontSize="30px"
                  fontWeight={"bold"}
                  color={theme.palette.primary.main}
                  sx={{ lineHeight: 1.2 }}
                >
                  {user?.balance} ฿
                </Typography>
              </Box>
            </BoxProfile>
          </Grid>

          <Grid item xs={4}>
            <PreMenu>
              {primaryMenus.map((menu) => (
                <Button
                  key={menu.key}
                  onClick={() => handleItemClick(menu.key)}
                  fullWidth
                  variant="contained"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: theme.spacing(1),
                    margin: theme.spacing(0, 0, 0.5, 0),
                    backgroundColor:
                      menu.key === "deposit"
                        ? "#4CAF50" // Green for "ฝากเงิน"
                        : menu.key === "withdraw"
                          ? "#F44336" // Red for "ถอนเงิน"
                          : theme.palette.primary.main,
                    color: "#fff",
                    borderRadius: "8px", // Rounded corners
                    "&:hover": {
                      backgroundColor:
                        menu.key === "deposit"
                          ? "#45A049"
                          : menu.key === "withdraw"
                            ? "#E53935"
                            : theme.palette.primary.dark,
                    },
                  }}
                >
                  <Typography variant="button" fontSize="16px" >
                    {menu.label}
                  </Typography>
                </Button>
              ))}
            </PreMenu>
          </Grid>

          {["คืนยอดเสีย", "รับทรัพย์"].map((label, index) => (
            <Grid item xs={6} key={index} sx={{ padding: 0 }}>
              <BoxProfile
                sx={{
                  backgroundColor: "rgba(252,220,130,1)",
                  borderRadius: 1,
                  boxShadow: 1,
                  overflow: "hidden",
                  width: "100%",
                  border: "1px solid rgba(252,220,130,1)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 0,
                  minHeight: "60px",
                }}
              >
                <Typography sx={{ paddingLeft: 1, color: "#000000", fontWeight: "bold" }}>
                  {label}
                </Typography>
                <Typography sx={{ paddingRight: 1, color: "#000000", fontSize: "20px", fontWeight: "bold" }}>
                  {numeral(cashback).format("0,0.00")}
                </Typography>
              </BoxProfile>
            </Grid>
          ))}

        </Grid>
      </Box>}


      {/* รับโปร */}
      {isBonus && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid container spacing={1} xs={12} md={5} sx={{
          // margin:
          padding: 1,
          borderRadius: 1,
          boxShadow: 1,
          overflow: "hidden",
          width: "100%",
          backgroundColor: "#061114", // Dark background
          border: "2px solid rgba(252,220,130,1)", // Border color from the image
        }}>

          <Grid item xs={12}>
            <BoxProfile
              sx={{
                padding: 0, // Removed padding for a tighter fit

                borderRadius: 1,
                paddingTop: 0.75,
                boxShadow: 1,
                overflow: "hidden",
                width: "100%",
                backgroundColor: "#061114", // Darker background for profile box
                border: "2px solid rgba(252,220,130,1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Aligns icon to the left and text to the right
              }}
            >
              <PermIdentityOutlinedIcon
                sx={{
                  width: 60,
                  height: 60,
                  color: "rgba(252,220,130,1)", // Icon color matching the border
                  marginLeft: 2, // Add margin to the left to create space
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end", // Align items to the right
                  flexGrow: 1, // Ensures the box takes up remaining space
                  paddingRight: 2, // Adds padding to the right
                }}
              >
                <Typography color={theme.palette.primary.main} fontWeight="bold">
                  {
                    promotionHistory
                      ? isPromotionHistoryLoading
                        ? "-"
                        : promotionHistory?.promotion.name
                      : "-"
                  }                
                </Typography>
                <Typography color={theme.palette.primary.main} textAlign="right">
                  ยอดเงินคงเหลือ
                </Typography>
                <Typography
                  align="right"
                  fontSize="32px"
                  fontWeight={"bold"}
                  color={theme.palette.primary.main}
                >
                  {user?.balance} ฿
                </Typography>
              </Box>
            </BoxProfile>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              spacing={2}
              sx={{
                padding: 0,
                display: 'flex',
                justifyContent: 'space-between', // Spread the buttons evenly
                alignItems: 'center',
              }}
            >
              {proMenus.map((menu) => (
                <Grid item key={menu.key} xs={12 / proMenus.length}>
                <Button
                  onClick={() => handleItemClick(menu.key)}
                  fullWidth
                  variant="contained"
                  disabled={menu.key === "removePro" && currentBalance >= 5}  
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: theme.spacing(1),
                    margin: theme.spacing(0, 0, 0.5, 0),
                    backgroundColor:
                      menu.key === "removePro"
                        ? "#4CAF50" 
                        : menu.key === "withdraw"
                          ? "#F44336" 
                          : theme.palette.primary.main,
                    color: "#fff",
                    borderRadius: "8px", 
                    "&:hover": {
                      backgroundColor:
                        menu.key === "removePro"
                          ? "#45A049"
                          : menu.key === "withdraw"
                            ? "#E53935"
                            : theme.palette.primary.dark,
                    },
                  }}
                >
                  <Typography variant="button" fontSize="16px">
                    {menu.label}
                  </Typography>
                </Button>
              </Grid>
              
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>}
    </>
  )

  const filteredMenus = config?.cashback_type && config?.cashback_type === 'custom'
    ? menus.filter(
      (menu) => !["refund"].includes(menu.key)
    )
    : menus.filter(
      (menu) => !["cashbackTurnover", "cashbackWinlose"].includes(menu.key)
    )

  return (
    <Stack spacing={1}>

      {renderUserCard}


      <Box position="relative" paddingLeft={3} paddingRight={3}>
        <NavigationButton onClick={() => scrollMenu("left")} sx={{ left: -10 }}>
          <Icon icon="ic:round-chevron-left" />
        </NavigationButton>
        <MenuWrapper ref={menuRef}>
          {filteredMenus.map((menu) => (
            <Box
              key={menu.key}
              onClick={() => handleItemClick(menu.key)}
              sx={{ minWidth: isMobile ? "20%" : "auto", flexShrink: 0 }}>
              <Item>
                <StyledBox id="icon-container" flexDirection="column" sx={{backgroundColor:"#061114"}} >
                  <Icon id="icon" icon={menu.icon || ""} width={30} height={30} />
                  <Typography
                    id="label"
                    fontWeight={"400"}
                    fontSize="12px"
                    variant="button"
                    color={"#e6c361"}>
                    {menu.label}
                  </Typography>
                </StyledBox>
              </Item>
            </Box>
          ))}
        </MenuWrapper>
        <NavigationButton onClick={() => scrollMenu("right")} sx={{ right: -10 }}>
          <Icon icon="ic:round-chevron-right" />
        </NavigationButton>
      </Box>
    </Stack>
  );
}
